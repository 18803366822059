import { useTheme } from '@mui/material';
const header = 'Sansita One'
const body = 'Open Sans'
const subheader = 'Vesper Libre'
const logo = "'Lilita One', cursive"
const typography = {
  fontFamily: [
    'Cabin',
    'Noto Serif',
    'Indie Flower',
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: subheader
  },
  h5: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: subheader
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: subheader
  },
  body1: {
    fontSize: '1rem',
    fontFamily: body
  },
  small: {
    fontSize: '.9rem',
    fontFamily: body
  },
  logo: {
    fontSize: '3rem',
    fontFamily: logo,
    strokeWidth: 0,
  },
  sectionHeader: {
    fontSize: '2rem',
    fontFamily: logo,
    strokeWidth: 0,
  },
  subtitle: {
    fontSize: '1.1rem', 
    fontStyle: 'italic'
  },
  caption: {
    fontSize: '.9rem', 
  },
  subtitle: {
    fontSize: '1.2rem'
  },

  button: {
    textTransform: "none",
  },

};

export default typography;