import { lazy } from 'react';
import { Main } from './../main';
import { HomePage } from './../pages/homepage';
import { CovidPage } from './../pages/covid';
import { SuspenseWrapper } from './../main/components/containers';

const DrugDiscoveryPage = lazy(() => import('./../pages/drugdiscovery'))
const PDBViewer = lazy(() => import('./../main/components/pdbviewer'))
const routes = [
    {
      path: '/',
      exact: true,
      element: <Main />,
      children: [
        {
            path: '/',
            exact: true,
            // element: <HomePage />,
            element: <HomePage />
        },
        {
          path: 'drug_discovery/',
          element: <SuspenseWrapper><DrugDiscoveryPage /></SuspenseWrapper>,
          // element: <Box sx={{display: 'rlex', justifyContent: 'center', mt: 10, width: '100%'}}><TreeWrapper /></Box>
        },
        {
          path: 'covid/',
          element: <SuspenseWrapper><CovidPage /></SuspenseWrapper>
        }
      ]
    }
]

export default routes;