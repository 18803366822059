import { Suspense, lazy } from 'react';
import { 
    Box,
    Divider
} from '@mui/material';
import { BaseContent } from './../BasePage'
import { Typography } from '@mui/material';
import { VaccinationTimeline } from './../../main/components/timeline';
import SmallpoxBoys from './../../assets/images/polio_boys.png';
import { SuspenseWrapper } from './../../main/components/containers'

// import { EpidemiologyGrid } from './../../main/components/epidemiology';
const  RadialPhyloTree  = lazy(() => import('./../../main/components/tree'))
const  EpidemiologyGrid  = lazy(() => import('./../../main/components/epidemiology'))

// import { RadialPhyloTree } from './../../main/components/tree';

export const CovidPage = props => {

    return <BaseContent> 
        <Box sx={{mb: 10}}>
            <Typography variant="h1" fontWeight="bold" >Timeline of the Smallpox Vaccination</Typography>
        </Box>
        <VaccinationTimeline />  
        <Divider sx={{width: '100%', background: theme => theme.palette.text.secondary, height: 1, mt: 10, mb: 3}}></Divider>
        
        <Typography variant="h2">Epidemiology Dynamics</Typography>
        <Typography sx={{mt: 2}}>
            SIR models capture the dynamics of viral spread within a population. In an SIR model, and individual 
            is in one of three states which can update at each 'round' of the simulation. The three states are: 
            <ul>
                <li>Susceptible to infection (S) </li>
                <li>Infected and contagious (I) </li>
                <li>Recovered or dead (R). </li>
            </ul>
        </Typography>

        <Typography sx={{mt: 2}}>
            Typically, differential equations are leveraged to compute SIR ratios. 
            While this provides great flexibility to build sophisticated models, it comes at the disadvantage of requiring well-defined functions 
            in the face of uncertainty.
        </Typography>

        <Typography sx={{mt: 2}}>
            In the following models, a population is represented as a grid, with each cell an individual. If an individual is infected, she can infect 
            her neighbors with a probability dependent on the neighbors vaccination status. 
        </Typography>
        <Typography sx={{mt: 2, mb: 4}}>
            Adjust the parameters below to approximate the effect of a parameter on viral spread. 
            <Typography fontWeight="bold" fontStyle="italic">
                In particular, adjusting the percentage of the population that is vaccinated (vax %) has a minor effect until a threshold is 
                reached, in which the spread of the virus drops significantly. This represenents a concept that was missing in a lot 
                of public discourse about the vaccinations to the COVID pandemic. Namely: <br /><br />
                <Typography color="primary" fontStyle="normal" sx={{fontSize: '1.5rem', fontWeight: 'bold'}}>Vaccines are tools of populations, not individuals.</Typography>
            </Typography>
        </Typography>
        <SuspenseWrapper>
            <EpidemiologyGrid />
        </SuspenseWrapper>

        <Divider sx={{width: '100%', background: theme => theme.palette.text.secondary, height: 1, my: 3}}></Divider>
        <Typography sx={{mt: 2}}>
            With this simplified model, you can abstract pandemic response strategies to probablities. For example, being vaccinated, 
            social distancing, wearing mask, and adhering to quarantine all reduce the probablity of transmission. No further specifics 
            are required.
            <Typography sx={{mt: 2}}>
                Of course, this model is only a crude representation. 
            </Typography>
        </Typography>
        <Typography sx={{mt: 2}}>
            GISAID (Global Initiative of Sharing All Influenza Data) maintains a repository of all Corona virus 19 sequencing information, 
            with details about the data collected, location, and state of patient. The following represents the phylogenetic relationship 
            between Corona virus since the beginning of the pandemic.  
        </Typography>
        <Typography sx={{mt: 2}}>

            It uses a subset of data found at GISAID, and inspired by as a set of open-source tools maintained by nextstrain. To see the 
            full spectrum of nextstrain tools, as well as a more realistic simulation of viral spread of the the corona virus, please 
            visit <a href="https://nextstrain.org/" target="_blank">nextrain</a> to learn more.
        </Typography>
        <Box sx={{my: 3}}></Box>
        <SuspenseWrapper >
            <RadialPhyloTree />
        </SuspenseWrapper>
   </BaseContent>
}