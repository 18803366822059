
import './App.css';
import { useEffect, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router} from "react-router-dom";
import { Provider } from "react-redux";
import store from './store';
import useTheme from './theme';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { client } from './client';
function App() {

    const [mode, setMode] = useState('light')
    const content = useRoutes(routes)


    
    useEffect(() => {
      let _mode = window.localStorage.getItem('mode')

      if(!_mode) {
          window.localStorage.setItem('themeName', 'light')
          _mode = 'light'
      }
      setMode(_mode)
    }, [])




    return (<>
      <ApolloProvider client={client}>
        <HelmetProvider context={{}}>
            <CssBaseline />
            <Box className="app" sx={{height: '100%', overflowX: 'hidden'}}>
              <Helmet>
                <title>Bioscijs</title>
              </Helmet>
              {content}
            </Box>

        </HelmetProvider>
      </ApolloProvider>
    </>
    );
}

export default App;
