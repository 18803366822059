
import { fetcher } from './../fetcher';

const BASE_URL = process.env.REACT_APP_BASE_URL + '/'





const load_tree = () => {
    let url = BASE_URL + 'demo/load_tree/'
    return fetcher(url, {
        method: 'get', 
    })
}

const query_protein_interactions = ({uniprotID}) => {

    let url = BASE_URL + `demo/query_protein_interactions/${uniprotID}/`

    return fetcher(url, {
        method: 'get'
    })
}

const query_protein_metadata = ({stringId}) => {
    let url = BASE_URL + `demo/query_protein_metadata/${stringId}/`
    return fetcher(url, {
        method: 'get'
    })
}

const query_protein_for_drug_info = ({uniprotID}) => {
    let url = BASE_URL + `demo/query_protein_for_drug_info/${uniprotID}/`

    return fetcher(url, {
        method: 'get'
    })
}
const query_cosmic_genes = () => {
    let url = BASE_URL + 'demo/query_cosmic_genes/'
    return fetcher(url, {
        method: 'get'
    })
}

export const demo = {
    load_tree,
    query_protein_interactions,
    query_protein_metadata,
    query_protein_for_drug_info,
    query_cosmic_genes
}