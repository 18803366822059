
import { 
    Box, 
    Avatar, 
    Stack,
    Typography
} from '@mui/material';

const Description = ({children}) => (
    <Typography color="textSecondary" sx={{fontSize: '.8rem', lineHeight: '.9rem'}}>{children}</Typography>
)
export const DefaultEvent = props => {
    const { name, description, date } = props
    return <Box sx={{position: 'relative'}}>
            <Typography sx={{position: 'relative', top: -20}} variant="h6" fontWeight="bold">{date}</Typography>
            <Typography variant="h6" sx={{fontSize: '1.1rem'}} fontWeight='bold'>{name}</Typography>
            <Description>{description}</Description>

    </Box>
}