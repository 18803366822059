import { useMemo } from 'react';
import { Box, Avatar as MuiAvatar } from '@mui/material'
import { ChartWrapper } from './../containers';
import tl_nodes from './vaccination_events';
import { styled } from '@mui/material/styles';


const Avatar = styled(MuiAvatar)(({theme}) => ({
    '& img': {
        objectFit: 'contains', 
        height: '100%'
    }
}))

const Node = ({name, time, item}) => {
    return <Box>

    </Box>
}

const Timeline = ({nodes}) => (
    <Box>
        
    </Box>
)



function buildTimeline(timeline, width, r) {

    // set x and y position 
    width = Math.max(width, 400)
    
    // render small screens without offset 
    const should_offset = width > 550
    
    const mid = width / 2
    const leftside = mid - r
    const rightside = mid + r
    const height_increment = 4 * r
    const boxwidth = mid - r - 20
    return timeline.map((tl, i) => ({
        ...tl, 
        x: i % 2 === 0 ? leftside : rightside,
        y: (i) * height_increment,
        r: r,
        even: i % 2 === 0,
        boxwidth: boxwidth
    }))
}

const TimelineEvent = ({node}) => (
    <Box sx={{
        width: node.r * 2, 
        height: node.r * 2, 
        background: 'black',
        position: 'absolute',
        borderRadius: '50%', 
        top: node.y,
        left: node.x - node.r,
        
    }}>
        {
            node.Image && <Avatar sx={{width: node.r * 2, height: node.r * 2}} alt={node.name} src={node.Image} />
        
            }
    </Box>
)
const TimelineBox = ({node}) => (
    <Box sx={{
        position: 'absolute',
        width: node.boxwidth, 
        height: 150, 
        borderTop: `1px solid black`,
        top: node.y,
        left: node.even ? node.x - node.boxwidth - node.r - 10 : node.x + node.r + 10,
        display: 'flex',

    }}>
        <node.Component {...node} />
    </Box>   
)


const VaxTimeline = ({width}) => {
    const r = 30
    const nodes = useMemo(() => buildTimeline(tl_nodes, width, r), [tl_nodes, width, r])

    return <Box sx={{position: 'relative', width, height: nodes[nodes.length-1].y + 100, m: 0, p: 0}}>
        {nodes.map((ni, i) => (
            <TimelineEvent key={'' + i} node={ni} >

            </TimelineEvent>
        ))}
        {nodes.map((ni, i) => (
            <TimelineBox key={'' +i} node={ni}>

            </TimelineBox>
        ))}

    </Box>
    return (
        <svg width={width} height={nodes[nodes.length-1].y + 100}>
            <rect width={width} height={nodes[nodes.length-1].y + 100} fill="rgba(0,0,0,.05)"></rect>
        </svg>
    )
}
const VaccinationTimelineWrapper  = props => {
    return (
        <ChartWrapper render={({width}) => (
            <VaxTimeline width={width} nodes={tl_nodes}></VaxTimeline>
        )}/>
    )
}

export const VaccinationTimeline = () => <VaccinationTimelineWrapper />