import merge from "deepmerge";
import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material/';

const theme = createTheme()


// 001E6C
// FCD900
// E8630A
// FCD900
// 035397
const light = merge(theme.palette, {
    mode: 'light',
    primary: {
        main: colors.lightBlue[700],
        dark: colors.lightBlue[900],
        light: colors.lightBlue[300],
        xlight: colors.lightBlue[50],
        contrastText: '#ffffff'
    },
    secondary: {
        main: colors.orange[400],
        dark: colors.orange[800],
        light: colors.orange[300]
    },
    error: {
        main: colors.deepOrange[800]
    },
    background: {
        appBar: '#2c2c2c',
        main: '#F7F9FC',
        paper: '#fff'
    },
    text: {
        appBar: '#fff',
    }
})

const dark = merge(light, {
    mode: 'dark',
    background: {
        appBar: '#0a001f'
    },
    primary: {
        main: colors.blue[200]
    },
    text: {
        appBar: '#eee'
    }
})

// export const dark = merge(light, {
//     name: 'dark', 
//     palette: {
//         mode: 'dark',
//         primary: {
//             main: '#ff00ff'
//         }, 
//         secondary: {
//             main: '#ffff00'
//         }
//     }
// })

export default {
    light,
    dark
}