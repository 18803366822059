import { configureStore } from '@reduxjs/toolkit';
import { mainReducer } from './../main'
import { treeReducer } from './../main/components/tree/slice'
import allReducers from './../main/dispatcher';
const store = configureStore({
    reducer: {
        ...allReducers
        // main: mainReducer,
        // tree: treeReducer
    }
})

export default store;