
import { Outlet } from 'react-router-dom';
import { useEffect, useRef, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    Box, 
    Button,
    Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { selectTheme, setTheme, setAppbarHeight, selectAppbarHeight } from './slice/mainSlice.js';
import useTheme from './../theme';
import { Navbar} from './components/navbar';
import AOS from 'aos';


export const Scroller = props => {
    return <Box sx={{position: 'relative', height: props.height, overflow: 'hidden'}}>
        <Box sx={{position: 'relative', height: '100%', overflow: 'scroll'}}>
            {props.children}
        </Box>
    </Box>
}


export const Main = props => {

    const _theme = useSelector(selectTheme)
    const theme = useTheme(_theme.name)
    
    const appbarHeight = useSelector(selectAppbarHeight)
    
    const dispatch = useDispatch()
    const ref = useRef(null)

    useEffect(() => {
        const saved_theme = window.localStorage.getItem('themeName')
        if(!saved_theme) { return }
        
        dispatch(setTheme({theme: {name: saved_theme}}))
        
    }, [])

    useEffect(() => {
        if(!ref?.current ) { return }
        const height = ref.current.getBoundingClientRect().height
        dispatch(setAppbarHeight({appbarHeight: height}))
    }, [ref?.current])

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
            once: true
        });
    }, [])

    return <ThemeProvider theme={theme}>
        <Box sx={{position: 'absolute', width: '100%', height: '100%', background: theme => true ? theme.palette.background.main : 'orange', zIndex: -1, top: 0, left: 0}}></Box>
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            flex: 1, 
            height: 'auto',
            width: '100%', 
            background: theme.palette.background.main
        }}>
            <Box ref={ref}>
                <Navbar />
            </Box>
            <Box sx={{
                display: 'flex', 
                flex: 1, 
                minHeight: `calc(100vh - ${appbarHeight}px)`, 
                mr: 0, 
                mt: 0, 
                position: 'relative',
            }}>
                <Outlet />
            </Box>
            <Box sx={{
                color: 'white', 
                py: 2,
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                background: theme => theme.palette.background.appBar,
                flexDirection: 'column'
            }}>

                    <Typography >BiosciJS</Typography>

                <Typography >@Copyright 2022. Adam Conn.</Typography>
            </Box>
        </Box>

    </ThemeProvider>
}

export default Main;
