import DrugIconPng from './drug_icon.png'
import VirusIconPng from './virus_icon.png'
import DnaIconPng from './dna_icon.png'
import TissueIconPng from './tissue_icon.png'
import {
    Avatar 
} from '@mui/material';


export const DrugIcon = props => {  
    return <Avatar alt="Drug Discory Page" src={DrugIconPng} {...props}/>
}

export const VirusIcon = props => (<Avatar alt="Covid Lineages" src={VirusIconPng} {...props} />)
export const DnaIcon = props => (<Avatar alt="Mutations" src={DnaIconPng} {...props} />)
export const TissueIcon = props => (<Avatar alt="Tissue types" src={TissueIconPng} {...props} />)


