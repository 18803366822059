import drugPageReducer from './pageSlice';
import {
    updateActiveProtein,
    queryProteinInteractions,
    updateSecondaryProtein,
    updatePotentialSecondaryProtein,
    queryProteinForDrugInfo,
    queryCosmicGenes
} from './pageSlice'

export const DRUGPAGE_TYPES = {
    UPDATE_ACTIVE_PROTEIN: 'DRUGPAGE_UPDATE_ACTIVE_PROTEIN',
    QUERY_PROTEIN_INTERACTIONS: 'DRUGPAGE_QUERY_PROTEIN_INTERACTIONS',
    UPDATE_SECONDARY_PROTEIN: 'DRUGPAGE_UPDATE_SECONDARY_PROTEIN',
    UPDATE_POTENTIAL_SECONDARY_PROTEIN: 'DRUGPAGE_UPDATE_POTENTIAL_SECONDARY_PROTEIN',
    QUERY_PROTEIN_FOR_DRUG_INFO: 'DRUGPAGE_QUERY_PROTEIN_FOR_DRUG_INFO',
    QUERY_COSMIC_GENES: 'DRUGPAGE_QUERY_COSMIC_GENES'
}

export const DRUGPAGE_SLICE = {
    DRUGPAGE_UPDATE_ACTIVE_PROTEIN: updateActiveProtein,
    DRUGPAGE_QUERY_PROTEIN_INTERACTIONS: queryProteinInteractions,
    DRUGPAGE_UPDATE_SECONDARY_PROTEIN: updateSecondaryProtein,
    DRUGPAGE_UPDATE_POTENTIAL_SECONDARY_PROTEIN: updatePotentialSecondaryProtein,
    DRUGPAGE_QUERY_PROTEIN_FOR_DRUG_INFO: queryProteinForDrugInfo,
    DRUGPAGE_QUERY_COSMIC_GENES: queryCosmicGenes
}

export { selectDrugDiscoveryPageState } from './pageSlice'; 
export { drugPageReducer };
export default drugPageReducer;
