
const BASE_URL = process.env.REACT_APP_BASE_URL

// initialize the fetch object to minimize code repeat at every request
// https://stackoverflow.com/questions/44820568/set-default-header-for-every-fetch-request
function updateOptions(options) {

  const EMBEDDED_IN_DJANGO = false
  const csrf_token = EMBEDDED_IN_DJANGO 
    ? document.getElementById('url-container').getAttribute('csrf_token')
    : ''
  const update = { ...options }
  update.headers = Object.assign({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        //'X-CSRF-Token': csrf_token
  }, update.headers ? update.headers : {})

  if(update.jwt) {
    const token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''
    update.headers = Object.assign(update.headers, {'Authorization': `Bearer ${token}`})
  }
    
    // replace this logic with jwt if convenient
    if(csrf_token) {
        //update.headers['X-CSRFToken'] = csrf_token
    }

  //alert('inside update returning: ' + JSON.stringify(update))
  return update;
}


export function fetcher(url, options) {

  return fetch(url, updateOptions(options));
}

export default fetcher;