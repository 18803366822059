
const components = {
    MuiAppBar: {
        defaultProps: {
            elevation: 0
        }, 
        styleOverrides: {
            root: {
                padding: 10, 
                paddingLeft: 5
            }
        },
    },

    MuiButtonBase: {
        defaultProps: {
        disableRipple: true,
        },
    },

    MuiSelect: {
        defaultProps: {
            disableScrollLock: true
        }
    },
    MuiDivider: {
        defaultProps: {
            marginTop: '5px',
            marginBottom: '5px',
            height: 2,
            width: '100%'
        }
    },
    MuiTypography: {
        defaultProps: {
            maxWidth: 800
        }
    }
}


export default components