import { DefaultEvent } from './events/DefaultEvent';
import PharaoImage from './../../../assets/timeline/ramsesV.jpeg'
import ChineseVariolation from './../../../assets/timeline/chinese_variolation.png'
import LadyMontague from './../../../assets/timeline/montague.png'
import Milk from './../../../assets/timeline/milk.png'
const nodes = [
        {
                name: 'First recorded case of smallpox',
                description: 'Description of smallpox appears in Egyptian, Indian, and Chinese writings. Pharaoh Ramses V of illness described by postules and a yellow rash, indicative of smallpox',
                date: '1157 B.C.',
                Image: PharaoImage,
                Component: DefaultEvent
        },
        {
                name: 'Practice of immunity through Variolation',
                description: 'Writings from China describe blowing smallpox postules into the nostrils to reduce smallpox infections during outbreak',
                date: '1122 B.C.',
                Image: ChineseVariolation,
                Component: DefaultEvent
        },
        {
                name: 'Lady Montague visits Turkey',
                description: 'The English Lady Montague becomes an avid supporter of variolation, returning the practice to England after a diplomatic trip to Turkey. To convince a skeptical public, she practices on prisoners in exchange for their freedom.',
                date: '1716 A.D.',
                Image: LadyMontague,
                Component: DefaultEvent
        },
        {
                name: 'Onesimus - A west-African slave brings variolation to America',
                description: 'Onesimus convinces "owner" Mather of the effectiveness of variolation. Mather advocates the practice and demonstrates a reduction in deaths when variolation ensues.',
                date: '1716 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'Milk maids are known to evade severe infections',
                description: "Observations are noted that milk maids with previous cases of Smallpox relative Cowpox don't suffer the extreme symptoms of small pox infection, leaving them free from scarring and disfugurement",
                date: '19th century',
                Image: Milk,
                Component: DefaultEvent
        },
        {
                name: 'The first vaccination attributed to Edward Jenner',
                description: "Edward Jenner publishes 'An Inquiry into the Causes and Effects of the Variole Vaccine', a clinical description of his cases in which he uses Cowpox, rather than smallpox, for variolation",
                date: '1798 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'The first vaccination attributed to Edward Jenner',
                description: "Edward Jenner publishes 'An Inquiry into the Causes and Effects of the Variole Vaccine', a clinical description of his cases in which he uses Cowpox, rather than smallpox, for variolation",
                date: '1798 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'The government mandated vaccinations.',
                description: "The Anti-Vaccination league forms in response to government mandates. Articles and journals are filled with political cartoons with dire warning that vaccination with cowpox makes you grow a tail and horns.",
                date: '1853 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'Jacobson verse Massachusetts',
                description: "Henning Jacobson challenges mandatory vaccination laws, claiming they are in violation of his religious and persoanl liberty. The supreme court rules in favor of compulsory vaccinations, saying individual liberty must sometimes give way to the common good.",
                date: '1798 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'Jonas Salk releases the polio vaccine',
                description: "Although vaccinations have been in use since Jenner, and variolation for 1000's of years before. Jonas Salk developed a new methodology that used a non-harmful variant of the polio virus.",
                date: '1953 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'Polio is declared eradicated',
                description: "Ali Maow Maaline is the last recorded case of smallpox in 1977. In 1980, smallpox is considered eradicated.",
                date: '1977 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: 'The Lancet opens the link between vaccinations and autism',
                description: "The Lancet publishes an article by Andrew Wakefield and colleagues, suggesting a correlation between vaccinations and autism.",
                date: '1998 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: "The Lancet retracts Wakefield's publication",
                description: "Upon investigation, Wakefield is found guilty of ethical violations and scientific misrepresentation. Eventually, it would turn out that their work was entirely based on fraud, motivated by financial incentive.",
                date: '2010 A.D.',
                Image: null,
                Component: DefaultEvent
        },
        {
                name: "The first mRNA vaccine passes FDA approval, and is the first vaccine used against a world wide pandemic",
                description: "In response to the Corona Virus, Pfizer releases vaccination to the public.",
                date: '2020 A.D.',
                Image: null,
                Component: DefaultEvent
        },
]

export default nodes;