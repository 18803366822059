import { useTheme } from '@mui/material/styles';

export const Logo = ({fontSize=64, fill="#fff000", shadow="#ffffff"}) => {

  const nodeStyle = {
    fill,
    strokeWidth: '0.408842',
    strokeLinecap: 'round',
    strokelineJoin: 'round',
    stopColor: '#000000',
  }
  const shadowStyle = {
    fill: shadow,
    opacity: .7,
    strokeWidth: '0.149162',
    strokeLinecap: 'round',
    strokelineJoin: 'round',
    stopColor: '#000000',
  }
  const defStyle = {
    fill: shadow,
    opacity: .7,
    strokeWidth: '0.49239',
    strokeLinecap: 'round',
    strokelineJoin: 'round',
    stopColor: '#000000',
  }


  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox="0 0 16.933333 16.933333"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs
      id="defs2"><clipPath
        clipPathUnits="userSpaceOnUse"
        id="clipPath356"><rect
          style={defStyle}
          id="rect358"
          width="86.370422"
          height="77.797989"
          x="69.35228"
          y="82.247734"
          rx="0.46721902"
          ry="0.46721902" /></clipPath>
          </defs><g
      id="layer1">
        <g
        id="g2043"
        transform="translate(50.573507,28.492094)"><g
          id="g4243"
          transform="matrix(0.41052704,0,0,0.36169315,-65.270282,-46.698342)"><path
            id="circle1380"
            style={nodeStyle}
            d="m 60.37926,51.067684 a 6.7278314,6.7278314 0 0 0 -6.727754,6.727755 6.7278314,6.7278314 0 0 0 5.357812,6.582544 l -0.0057,4.410583 a 6.1428719,6.1428719 0 0 0 -4.855001,6.003768 6.1428719,6.1428719 0 0 0 0.498161,2.412256 l -4.013192,2.969844 a 8.2332592,8.2332592 0 0 0 -5.956226,-2.549715 8.2332592,8.2332592 0 0 0 -8.233089,8.233606 8.2332592,8.2332592 0 0 0 8.233089,8.233089 8.2332592,8.2332592 0 0 0 8.233089,-8.233089 8.2332592,8.2332592 0 0 0 -0.711067,-3.346049 l 4.205945,-2.96571 a 6.1428719,6.1428719 0 0 0 3.886067,1.388545 6.1428719,6.1428719 0 0 0 1.604554,-0.217557 l 2.102197,3.731038 a 6.8494191,6.8494191 0 0 0 -2.309937,5.123718 6.8494191,6.8494191 0 0 0 6.849711,6.849195 6.8494191,6.8494191 0 0 0 6.849195,-6.849195 6.8494191,6.8494191 0 0 0 -6.849195,-6.849711 6.8494191,6.8494191 0 0 0 -1.982308,0.294556 l -1.849499,-3.957898 a 6.1428719,6.1428719 0 0 0 1.728577,-4.266923 6.1428719,6.1428719 0 0 0 -0.06305,-0.871781 l 2.463416,-0.807703 a 4.2998538,4.2998538 0 0 0 3.268017,1.509985 4.2998538,4.2998538 0 0 0 4.299996,-4.299479 4.2998538,4.2998538 0 0 0 -4.299996,-4.299996 4.2998538,4.2998538 0 0 0 -4.299479,4.299996 4.2998538,4.2998538 0 0 0 0.01034,0.277503 l -2.53783,0.60203 a 6.1428719,6.1428719 0 0 0 -3.060278,-2.243791 l -0.348816,-4.603336 a 6.7278314,6.7278314 0 0 0 5.240507,-6.560323 6.7278314,6.7278314 0 0 0 -6.728272,-6.727755 z" /><ellipse
            style={shadowStyle}
            id="circle2017"
            cx="41.352638"
            cy="82.548759"
            rx="2.3773706"
            ry="2.1127706" /><ellipse
            style={shadowStyle}
            id="ellipse2026"
            cx="65.789566"
            cy="86.604424"
            rx="2.2049642"
            ry="1.959553" /><ellipse
            style={shadowStyle}
            id="ellipse2028"
            cx="57.73978"
            cy="72.299759"
            rx="1.8829606"
            ry="1.6733881" /><ellipse
            style={shadowStyle}
            id="ellipse2030"
            cx="69.944885"
            cy="68.654228"
            rx="1.2056875"
            ry="1.0714952" /><ellipse
            style={shadowStyle}
            id="ellipse2032"
            cx="57.960564"
            cy="55.025238"
            rx="1.8829606"
            ry="1.6733881" />
          </g>
      </g>
    </g>
  </svg>
  
)
  }
