import { Suspense } from 'react'
import { Box } from '@mui/material'


const Loading = () => <Box>Loading...</Box>
export const SuspenseWrapper = ({Component, children}) => {
    Component = Component ? Component : Loading
    return <Suspense fallback={<Component/>}>
        {children}
    </Suspense>
}