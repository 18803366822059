import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    theme: {name: null},
    appbarHeight: null,
}

export const mainSlice = createSlice({
    name: 'main',
    initialState: initialState,
    reducers: {
        setTheme: (state, action) => {
            window.localStorage.setItem('theme', action.payload.theme.name)
            return {
                theme: {
                    ...state.theme,
                    ...action.payload.theme
                }
            }
        },
        setAppbarHeight: (state, action) => {
            state.appbarHeight = action.payload.appbarHeight
        }
    }
})


// actually this doesn't really need to be in a store
export const selectTheme = store => store.main.theme
export const selectAppbarHeight = store => store.main.appbarHeight

export const {
    setTheme,
    setAppbarHeight
} = mainSlice.actions


const mainReducer = mainSlice.reducer
export { mainReducer }
export default mainReducer;