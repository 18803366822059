let segments = [
        {
        name: 'MET', 
        rcsb: '2UZY',
        uniprot: 'P08581'
    },
    {
        name: 'BRCA1', 
        rcsb: '6GVW',
        uniprot: 'P38398'
    },
    {
        name: 'A1CF', 
        rcsb: '7TVO',
        uniprot: 'Q9NQ94'
    },
    {
        name: 'BRCA2', 
        rcsb: '1MIU',
        uniprot: 'P51587'
    },
    {
        name: 'AR', 
        rcsb: '3FY8',
        uniprot: 'P29274'
    },

    {
        name: 'RAF1', 
        rcsb: '6KKN',
        uniprot: 'A7E3S4'
    }
]

let index = 0
segments = segments.map(segment => {
    
    // between 400-3000 nucs
    const nuc_number = parseInt(Math.random() * 2600 + 400)

    // get between 10-30 mutations
    const mut_number = parseInt(Math.random() * 20 + 30)


    //get positions of mutations
    const nucs = [...new Array(nuc_number).keys()].map(ki => ki + index)
    
    const mutations = [...new Array(mut_number).keys()].map(ki => {
        return nucs[parseInt(Math.random() * (nucs.length - 1))]
    }).map(mut => ({
        position: mut, 
        freq: parseInt(Math.random() * 100)
    }))


    const new_segment = {
        ...segment, 
        region: [index, index + nuc_number],
        mutations: mutations
    }
    index = index + nuc_number 
    return new_segment


})

export { segments }