import { Helmet } from 'react-helmet-async';
import {
    Box,
    Grid,
    Paper as MuiPaper, 
} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import {
  appTitle,
  appDescription,
  appCanonicalUrl,
} from './../constants';

export const BasePage = props => {  

    return (<>
        <Helmet title={appTitle}>
            <meta name="description" content={appDescription} />
            <link rel="canonical" href={appCanonicalUrl} />
        </Helmet>
        <Grid
            container
            justifyContent="start"
            alignItems="center"
            flexDirection="column"
        >
            <Box sx={{height: '100%', width: '100%'}}>
                {props.children}
            </Box>
        </Grid>
    </>
    
    
    )
}


const Paper = styled(MuiPaper)(({theme}) => ({
    overflow: 'visible',
    padding: theme.spacing(12),
    maxWidth: '800px',
    margin: 'auto',
    background: theme.palette.background.paper,
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column'
}))


export const BasePaper = ({ children,  ...other }) => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={10} sx={{zIndex: 1}} >
                <Paper {...other}>
                    {children}
                </Paper>
            </Grid>
        </Grid>
    );
};


export const BaseContent = ({children, ...other}) => {
    return <BasePage>
        <BasePaper {...other}>
            {children}
        </BasePaper>
    </BasePage>
}