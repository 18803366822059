
import config from './config';
import { DrugIcon, VirusIcon } from './assets/icons';
export const externalLinks = {
  about: [
    // {
    //   label: `Platform version ${process.env.REACT_APP_REVISION ?? 'dev'}`,
    //   url: `https://github.com/opentargets/platform-app/releases/tag/${process
    //     .env.REACT_APP_REVISION ?? 'v0.1.1'}`,
    // },
    {
      label: 'Community forum',
      url: 'https://community.opentargets.org',
    },

    {
      label: 'Privacy notice',
      url: 'https://www.ebi.ac.uk/data-protection/privacy-notice/open-targets',
    },

    {
      label: 'Terms of use',
      url: 'https://platform-docs.opentargets.org/terms-of-use',
    },
  ],
  network: [
    { label: 'Science', url: 'https://www.opentargets.org/science' },
    { label: 'Publications', url: 'https://www.opentargets.org/publications' },
    { label: 'Genetics Portal', url: 'https://genetics.opentargets.org' },
    { label: 'Jobs', url: 'https://www.opentargets.org/jobs' },
    { label: 'Blog', url: 'https://blog.opentargets.org' },
  ],
  partners: [
    { label: 'Bristol Myers Squibb', url: 'https://www.bms.com' },
    { label: 'EMBL-EBI', url: 'https://www.ebi.ac.uk' },
    { label: 'GSK', url: 'https://www.gsk.com' },
    { label: 'Pfizer', url: 'https://pfizer.com' },
    { label: 'Sanofi', url: 'https://www.sanofi.com' },
    { label: 'Wellcome Sanger Institute', url: 'https://www.sanger.ac.uk' },
  ],
  help: [
    {
      label: 'Documentation',
      //icon: faBook,
      url: 'https://platform-docs.opentargets.org',
    },
    {
      label: 'Community',
      //icon: faDiscourse,
      url: 'https://community.opentargets.org',
      external: true,
    },
    {
      label: config.profile.helpdeskEmail,
      //icon: faEnvelope,
      url: `mailto: ${config.profile.helpdeskEmail}`,
      external: true,
    },
  ],
  // social: [
  //   // { icon: faDiscourse, url: 'https://community.opentargets.org' },
  //   { icon: faTwitterSquare, url: 'https://twitter.com/opentargets' },
  //   { icon: faLinkedin, url: 'https://www.linkedin.com/company/open-targets' },
  //   { icon: faYoutubeSquare, url: 'https://www.youtube.com/opentargets' },
  //   { icon: faGithubSquare, url: 'https://github.com/opentargets' },
  // ],
};



export const homepage_links = [
    {
        name: 'drug_discovery', 
        title: 'Drug Discovery',
        description: 'Use RNA-seq expression data to find novel targets affecting drug insensitivity',
        path: '/drug_discovery' ,
        Icon: DrugIcon
    },
    {
        name: 'covid', 
        title: 'Covid Lineages',
        description: 'Use data from GISAID and nextstrain to view phylogenetic timeline of Covid 19',
        path: '/covid',
        Icon: VirusIcon
    },

]


// Configuration for the main hamburger menu
export const mainMenuItems = config.profile.mainMenuItems ?? [
  // Documentation
  {
    name: 'Documentation',
    url: 'https://platform-docs.opentargets.org/getting-started',
    external: true,
  },
  // Downloads
  {
    name: 'Data downloads',
    url: '/downloads',
    external: false,
  },
  // API
  {
    name: 'API',
    url: '/api',
    external: false,
  },
  // Community
  {
    name: 'Community',
    url: 'https://community.opentargets.org/',
    external: true,
  },
  // Contact
  {
    name: 'Contact us',
    url: `mailto:${config.profile.helpdeskEmail}`,
    external: true,
  },
];


// App title.
export const appTitle = 'BiosciJS';
export const appDescription =
  'A Bioinformatics discovery platform';
export const appCanonicalUrl = 'https://bioscijs.com';

// Chunk sizes for server side pagination/download.
export const tableChunkSize = 100;
export const downloaderChunkSize = 2500;

// NA label.
export const naLabel = 'N/A';

export const colorRange = config.profile.colorRange ?? [
  '#e5edf4',
  '#ccdcea',
  '#b2cbe0',
  '#99b9d6',
  '#7fa8cc',
  '#6697c1',
  '#4c85b7',
  '#3274ad',
  '#1963a3',
  '#005299',
];

export const defaultRowsPerPageOptions = [10, 25, 100];

export const decimalPlaces = 3;

export const phaseMap = {
  0: 'Phase 0',
  1: 'Phase I',
  2: 'Phase II',
  3: 'Phase III',
  4: 'Phase IV',
};

export const sourceMap = {
  'FDA Information': 'FDA',
  FDA: 'FDA',
  'Clinical Trials Information': 'ClinicalTrials.gov',
  ClinicalTrials: 'ClinicalTrials.gov',
  'DailyMed Information': 'DailyMed',
  DailyMed: 'DailyMed',
  'ATC Information': 'ATC',
  ATC: 'ATC',
};

export const clinvarStarMap = {
  'practice guideline': 4,
  'reviewed by expert panel': 3,
  'criteria provided, multiple submitters, no conflicts': 2,
  'criteria provided, conflicting interpretations': 1,
  'criteria provided, single submitter': 1,
  'no assertion for the individual variant': 0,
  'no assertion criteria provided': 0,
  'no assertion provided': 0,
};

export const formatMap = {
  json: 'JSON',
  parquet: 'Parquet',
};

export const studySourceMap = {
  FINNGEN: 'FinnGen',
  GCST: 'GWAS Catalog',
  SAIGE: 'UK Biobank',
  NEALE: 'UK Biobank',
};
