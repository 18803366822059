import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from './../../../../utilities/api';



const initialState = {
    loaded: false,
}


export const loadTree = createAsyncThunk(
    'tree/loadTree',
    async (data, {rejectWithValue}) => {
        const response = await API.demo.load_tree()

        if(response.ok) {
            return response.json()
        } else {
            return rejectWithValue({'error': response.status})
        }
    }

)

const treeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {},
    extraReducers:  builder => {
        builder.addCase(loadTree.fulfilled, (state, action) => {
            state.tree = action.payload.tree
            state.meta = action.payload.meta
            state.loaded = true
        })
        .addCase(loadTree.rejected, (state, action) => {

        })


    }
    
})

const selectTree = state => state.tree

const treeReducer = treeSlice.reducer;
export { treeReducer };
export default treeReducer;
