/*
Return a 'TYPE -> dispatch function' map to create a single interace to dispatch actions.

src/dispatcher/ <- all components submit dispatches through here 

METHOD CALL:
dispatcher(dispatch, type, payload)

REQUIREMENTS: the string values need to be prepended with slice name 
examples
TYPES.TREE.LOAD_TREE -> "TREE_LOAD_TREE' (prepended with TREE to avoid name conflicts

const TREE_TYPES = {
    LOAD_TREE: TREE_LOAD_TREE
}
*/
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import mainReducer from './../slice/mainSlice';
import treeReducer, { TREE_TYPES, TREE_SLICE } from './../components/tree/slice';
import drugPageReducer, { DRUGPAGE_TYPES, DRUGPAGE_SLICE } from './../slice/drugpage/';


const SLICE_MAP = {
    ...TREE_SLICE,
    ...DRUGPAGE_SLICE
}

const dispatcher = (dispatch, type, payload) => {
    dispatch(SLICE_MAP[type](payload))
}

export const useDispatcher = () => {
    const dispatch = useDispatch()
    return (type, payload) => dispatcher(dispatch, type, payload)
}

export const TYPES = {
    TREE: TREE_TYPES,
    DRUGPAGE: DRUGPAGE_TYPES
}



export default {
    main: mainReducer,
    tree: treeReducer,
    drugpage: drugPageReducer
}