import React from 'react';
import {
  Grid,
  Typography,
  Hidden,
  Box,
  useMediaQuery,
} from '@mui/material/';
import { useTheme, styled } from '@mui/material/styles';
import { Helmet} from 'react-helmet-async';
import { Button, Link } from './../../main/components/mymui/'
import {
  appTitle,
  appDescription,
  appCanonicalUrl,
  externalLinks,
  mainMenuItems,
} from '../../constants';
import { ParticleSplash } from './ParticleSplash';
import { HomeBox } from './HomeBox';


import { homepage_links } from './../../constants';

const ButtonRow = () => {
    return (
        <Grid container alignItems="start" justifyContent="center" spacing={3} sx={{mt: 1}}>
            
            {homepage_links.map(({
                Icon,
                name,
                title, 
                description,
                path
            }) => (
                <Grid item xs={12} md={6} lg={6} >
                    { Icon !== null && <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Icon sx={{width: 100,  height: 100}}/> </Box>
                    }
                    <Button 
                        key={name}
                        name={name}
                        size="large" 
                        variant="outlined"
                        fullWidth
                        component={Link}
                        to={path}
                        sx={{mb: 1}}
                    >
                        <Typography component="div">{title}</Typography>
                    </Button>
                    <Typography color="textSecondary">{description}</Typography>
            </Grid>
            ))}

        </Grid>

    )
}

export const HomePage = props => {
    return (<>
        <Helmet title={appTitle}>
            <meta name="description" content={appDescription} />
            <link rel="canonical" href={appCanonicalUrl} />
        </Helmet>
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{background: 'transparent', height: '100vh'}}
        >

            { true && <ParticleSplash /> }
            <HomeBox>
                <Typography paragraph variant="caption" textAlign="center" sx={{mt: 3}}>
                    An ongoing collection of pipelines from open-source tools and scientific literature. 
                </Typography>
                <ButtonRow />
                {/* <Typography paragraph>
                    This site is a demo to conceptualize a small-pipeline that
                    combines full-stack technology with open-source tools in drug discovery. 
                </Typography>
                <Typography>
                    The pipeline consists of a user uploading RNA-seq expression data of a population of patients 
                    tumor cells. From the data, a subset of 700 genes will be used, along with the linear weights 
                    provided by xxx, to predict the patients insensitivies to common drugs. 
                </Typography>
                <Typography>
                    From the predicted insensitivies, and using the original patients sequence data, 
                    GWAS could be performed using the insensitivities to one or a few drugs as the 
                    phenotype measurement with tools such as openMM or gromacs.
                </Typography>
                <Typography>
                    If any targets are found, they can quickly be compared to drugs.
                </Typography> */}


            </HomeBox>
        </Grid>

    </>)
}

export default HomePage;


