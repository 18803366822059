import React from 'react';
import { 
    Grid, 
    Paper as MuiPaper, 
    Typography,
    Box
} from '@mui/material';
import config from '../../config.js';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Logo } from './../../assets/logo';


const Paper = styled(MuiPaper)(({theme}) => ({
    overflow: 'visible',
    padding: theme.spacing(12),
    maxWidth: '800px',
    margin: 'auto',
    background: theme.palette.background.main,
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column'
}))
// const useStyles = makeStyles(theme => ({
//   homeboxContainer: {
//   },
//   homeboxHeader: {
//     textAlign: 'center',
//     marginBottom: '20px',
//   },
//   title: {
//     color: theme.palette.grey[700],
//     bottom: '40px',
//     fontSize: '30px',
//     marginLeft: '7px',
//     position: 'relative',
//   },
//   logo: {
//     maxWidth: '30rem',
//     width: '100%',
//   },
//   note: {
//     backgroundColor: '#ffffcc',
//     textAlign: 'center',
//     padding: '15px 74px',
//   },
//   important: {
//     marginBottom: '10px',
//   },
// }));

export const HomeBox = ({ children }) => {
    const theme = useTheme()
    const Title = () => (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2}}>
                <Logo fill={theme.palette.secondary.main} shadow={theme.palette.primary.contrastText}/>
                <Typography variant="logo" color="primary">
                    Biosci<Box component="span" sx={{
                        color: theme => theme.palette.primary.main,
                        textShadow: theme => `2px 2px 2px ${alpha(theme.palette.primary.light, .3)}`,
                        fontSize: '3.4rem'
                    }}>JS</Box>
                </Typography>
            </Box>
        
    )
    return (
        <Grid item xs={12} sm={8} md={8} lg={8} sx={{zIndex: 1}}>
        <Paper>
            <Title />
            {children}
        </Paper>
        </Grid>
    );
};

export default HomeBox;
