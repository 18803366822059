import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { segments } from './mock_segments';
import { API } from './../../../utilities/api'


const initialState = {
    proteins: {
        loaded: false,
        proteins: null
    },
    active_protein: null,
    secondary_protein: null,
    potential_secondary_protein: null,
    network: {
        links: null,
        nodes: null,
        rerender: 0
    },
    open_targets: []
}

const SimpleQueryFactory = ({caller, name, error='Problem loading data. Please try again.', debug=false}) => {
    return createAsyncThunk(
        name,
        async (data, {rejectWithValue}) => {
            if(debug) {
                console.log(data)
            }
            const response = await caller(data)
            if(response.ok) {
                return await response.json()
            }
            return rejectWithValue({error: error})
        }
    )
}
export const queryProteinInteractions = SimpleQueryFactory({
    caller: API.demo.query_protein_interactions,
    name: 'queryProteinInteractions'
})

export const queryProteinForDrugInfo = SimpleQueryFactory({
    caller: API.demo.query_protein_for_drug_info,
    name: 'queryProteinForDrugInfo',
    debug: true
})

export const queryCosmicGenes = SimpleQueryFactory({
    caller: API.demo.query_cosmic_genes,
    name: 'queryCosmicGenes'
})

const pageSlice = createSlice({
    name: 'drugpage',
    initialState,
    reducers: {
        updateActiveProtein: (state, action) => {

            const { symbol } = action.payload
            state.active_protein = state.proteins.proteins.find(pi => pi.symbol === symbol)
        },
        updatePotentialSecondaryProtein: (state, action) => {
            const { protein } = action.payload 

            state.potential_secondary_protein = protein 

        }, 
        updateSecondaryProtein: (state, action) => {

            const { protein } = action.payload
            state.secondary_protein = protein
        }
    },
    extraReducers: builder => {
        builder
            .addCase(queryProteinInteractions.fulfilled, (state, action) => {
                const { links, nodes } = action.payload
                state.network = {
                    nodes,
                    links,
                    rerender: state.network.rerender + 1
                }
            })
            .addCase(queryProteinInteractions.rejected, state => state)
            

            .addCase(queryProteinForDrugInfo.rejected, state => state)
            .addCase(queryProteinForDrugInfo.fulfilled, (state, action) => {
                const data = action.payload

                state.open_targets.push({...data})
            })

            .addCase(queryCosmicGenes.rejected, state => {
                state.proteins.loaded = true
            })
            .addCase(queryCosmicGenes.fulfilled, (state, action) => {
                const data = action.payload
                const active_protein = data?.find(di => di.symbol === 'TP53')
                
                state.proteins = {
                    loaded: true,
                    proteins: data
                }
                state.active_protein = active_protein
            })
    }

})

export const selectDrugDiscoveryPageState = state => state.drugpage

export const {
    updateActiveProtein,
    updateSecondaryProtein,
    updatePotentialSecondaryProtein,
} = pageSlice.actions;

export default pageSlice.reducer 
