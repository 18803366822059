import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
    Box
} from '@mui/material';


// sets its width and passes to rendered prop
export function ChartWrapper(props) {
    const { padding=0, render, ...other } = props
    const [width, setWidth] = useState(0)
    const ref = useRef(null)
    const theme = useTheme()

    useEffect(() => {
        const v = ref.current.getBoundingClientRect().width
        setWidth(ref.current.getBoundingClientRect().width - 2 * parseFloat(theme.spacing(padding)))
    }, [])

    return <Box width='100%' padding={padding}>
        <Box ref={ref} sx={{ width: '100%', height: 0}}></Box> 
        {render({width, ...other})}
    </Box>
}

export default ChartWrapper;