
import { fetcher } from './../fetcher';

const BASE_URL = process.env.REACT_APP_BASE_URL + '/'

const load_user = async () => {

    const token = localStorage.getItem('access_token')
    
    if(!token) throw Error()

    return fetcher(BASE_URL + 'accounts/get_user/', {
        method: 'get',
        jwt: true,
        headers: {'Accept': 'application/json'}
    })
    .then(async response => {
        const ok = response.ok


        if(!ok) throw Error()
        return response.json()

       


    })
}


const verify_token = async () => {

    let token = localStorage.getItem('access_token')

    if(token) {
        return fetcher(BASE_URL + 'auth/token/verify/', {
            method: 'post', 
            body: JSON.stringify({token})
        })
        .then(data => {
            return data.status === 200
        })
        .catch(err => {
            return false
        })
    } else {
        return new Promise.resolve(false)
    }
}


const check_authenticated = async () => {
    
    // check access token is valid 
    // if not request a new access token 
    let token = localStorage.getItem('access_token')
    let refresh = localStorage.getItem('refresh_token')

    if(!token || !refresh) {
        return {isAuthenticated: false}
    }
    const access_is_valid = await verify_token()
    
    if(access_is_valid) {
        return {isAuthenticated: true} 
    }

    // get new token from refresh 
    return fetcher(BASE_URL + 'auth/token/refresh/', {
        method: 'post', 
        body: JSON.stringify({refresh})
    })
    .then(data => data.json())
    .then(data => {
        if('detail' in data) {
            return {isAuthenticated: false}
        } else {
            localStorage.setItem('access_token', data.access)
            return {isAuthenticated: true}
        }
    })
    .catch(err => ({isAuthenticated: false}))
}


const login = async data => {

    return fetcher(BASE_URL + 'auth/login/', {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(async response => {
        const ok = response.ok 
        const data = await response.json().then(data => data)
        return {
            success: ok,
            data
        }
    })

}



export const auth = {
    login,
    check_authenticated,
    load_user,
    
}