import treeReducer from './treeSlice';
import {
    loadTree
} from './treeSlice'



export const TREE_TYPES = {
    LOAD_TREE: 'TREE_LOAD_TREE',
}

export const TREE_SLICE = {
    TREE_LOAD_TREE:   loadTree
    
}


export { treeReducer };
export default treeReducer;
